<script setup>
import { Vue3Lottie } from "vue3-lottie";
import Icon from "@/Icons/jobs-icons.vue";
import likeJSON from "@/Lotti/like.json";
import bellJSON from "@/Lotti/bell.json";
import bellSimpleJSON from "@/Lotti/bell-simple.json";
import LoveJSON from "@/Lotti/love.json";
import rocketJSON from "@/Lotti/rocket.json";
import Axios from "axios";
import { onLongPress } from "@vueuse/core";
import { computed, onMounted, ref } from "vue";

const props = defineProps({
    post: Object,
    initialLikeCounts: Number,
    initialLikedUsers: Array,
    initialUserLikedType: String,
    showTittle:{
        type:Boolean,
        default:true
    }
});

const emit = defineEmits(["updateLikes"]);

const likeTypes = ["like", "bell", "love", "rocket"];
const lotties = {
    like: likeJSON,
    bell: bellJSON,
    love: LoveJSON,
    rocket: rocketJSON,
    bellSimple: bellSimpleJSON,
};
const liked = ref(false);
const direction = ref("forward");

const htmlRefHook = ref();
const longPressedHook = ref(false);

const likedUsers = ref(props.initialLikedUsers);
const userLikedType = ref(props.initialUserLikedType);
const lottieAnimationLike = ref(null);
const lottieAnimationBell = ref(null);
const lottieAnimationRocket = ref(null);
const lottieAnimationLove = ref(null);
const lottieAnimationBellSimple = ref(null);

const updateCountLikes = (likeCounts, likeTypes) => {
    emit("updateLikes", likeCounts, likeTypes);
};
function onLongPressCallbackHook(e) {
    let likePopup =
        e.target.parentElement.parentElement.querySelector(".likePopup");
    likePopup.classList.add("!opacity-100");
    likePopup.classList.add("!visible");
    longPressedHook.value = true;
}

onLongPress(htmlRefHook, onLongPressCallbackHook, {
    modifiers: {
        prevent: true,
    },
});

function openlikeWindow(elem) {
    if (window.innerWidth > 768) {
        let wrapper = elem.target.closest(".likeWindow");
        let likePopup = wrapper.querySelector(".likePopup");
        likePopup.classList.add("!opacity-100");
        likePopup.classList.add("!visible");
    }
}
function closelikeWindow(elem) {
    if (window.innerWidth > 768) {
        let wrapper = elem.target.closest(".likeWindow");
        let likePopup = wrapper.querySelector(".likePopup");
        likePopup.classList.remove("!opacity-100");
        likePopup.classList.remove("!visible");
    }
}
function removeWindowVisibility(event) {
    let likeWindow = event.target.closest(".likePopup");
    likeWindow.classList.remove("special");
    likeWindow.classList.remove("!opacity-100");
    likeWindow.classList.remove("!visible");
}

function removeWindowVisibilityAfterLike(event) {
    let likeWindow = event.target.closest(".likePopup");
    setTimeout(() => {
        likeWindow.classList.remove("special");
        likeWindow.classList.remove("!opacity-100");
        likeWindow.classList.remove("!visible");
    }, 1500);
}

async function toggleLike(type) {
    try {
        let typeBell = ["bellSimple", "bell"].includes(type);
        if (typeBell) type = "bell";
        if (userLikedType.value === "bell") {
            typeBell = true;
        }
        // console.log(userLikedType.value, typeBell, type);
        if (userLikedType.value === type) {
            // Unlike the post
            const response = await Axios.delete(
                route("posts.unlike", props.post?.id)
            );
            const data = response.data;
            if (typeBell) {
                playUnlike("bellSimple");
            }
            playUnlike(type);
            if (data.success) {
                updateCountLikes(data.likeCounts, data.likeTypes);
                userLikedType.value = data.userLikedType;
                // likedUsers.value = data.likedUsers;
                liked.value = false;
            }
        } else {
            // Like the post with the specified type
            const response = await Axios.post(
                route("posts.like", props.post?.id),
                { type }
            );
            const data = response.data;

            if (data.success) {
                updateCountLikes(data.likeCounts, data.likeTypes);
                if (userLikedType.value) {
                    if (typeBell) {
                        playUnlike("bellSimple");
                    }
                    playUnlike(userLikedType.value);
                }
                userLikedType.value = data.userLikedType;
                // likedUsers.value = data.likedUsers;
                liked.value = true;
            }
        }
    } catch (error) {
        console.error("An error occurred:", error);
        // Optionally, handle errors or show a message to the user
    }
}
const playLike = (type) => {
    let animation = getAnimationType(type);
    animation.value.setDirection("forward");
    animation.value.play();
    direction.value = "forward";
};

const playUnlike = (type) => {
    let animation = getAnimationType(type);
    animation.value.setDirection("reverse");
    animation.value.play();
    direction.value = "reverse";
};

const getAnimationType = (type) => {
    let animation;
    if (type === "like") {
        animation = lottieAnimationLike;
    } else if (type === "bell") {
        animation = lottieAnimationBell;
    } else if (type === "love") {
        animation = lottieAnimationLove;
    } else if (type === "rocket") {
        animation = lottieAnimationRocket;
    } else if (type === "bellSimple") {
        animation = lottieAnimationBellSimple;
    }
    return animation;
};
function handleLottiePlay(type) {
    playLike(type);
    toggleLike(type);
}

onMounted(() => {});
</script>

<template>
    <div
        @mouseover="openlikeWindow($event)"
        @mouseleave="closelikeWindow($event)"
        @touchstart="openlikeWindow($event)"
        @touchend="closelikeWindow($event)"
        ref="htmlRefHook"
        class="relative w-full pl-4 likeWindow"
        :class="{'left-[12%] right-0 pl-1' : !props.showTittle}"
    >
        <div
            @click="
                handleLottiePlay('bellSimple');
                playLike('bell');
            "
            class="relative z-10 flex-auto block w-full py-1 cursor-pointer"
        >
            <div
                class="flex items-center justify-center text-sm font-bold pointer-events-none group/icon"
                :class="{'flex-col' : !props.showTittle}"
                >
                <Vue3Lottie
                    ref="lottieAnimationBellSimple"
                    :animationData="lotties['bellSimple']"
                    :height="45"
                    :width="45"
                    :loop="false"
                    :autoPlay="initialUserLikedType === 'bell'"
                    class="cursor-pointer "
                    :class="{
                        '!m-0': props.showTittle
                    }"
                />
                <span class="hidden lg:block" v-if="props.showTittle"> {{ $t("Like") }}</span>
                <span v-if="!props.showTittle"> {{ props.initialLikeCounts}}</span>
            </div>
        </div>
        <div
            class="absolute likePopup invisible transition-all z-20 duration-300 -translate-y-1/2 opacity-0 top-[calc(50%_-_45px)] left-[calc(100%_-_47px)]"
        >
            <div
                class="p-3 transition-all bg-white border rounded-full pointer-events-none border-secondary-10 shadow-card"
            >
                <div class="flex items-center gap-3 pointer-events-auto">
                    <div class="relative group">
                        <div
                            class="flex relative items-center overflow-hidden group-hover:scale-[1.75] cursor-pointer origin-bottom transition-all justify-center w-8 h-8 p-0.5 border bg-[#535EC7] border-white rounded-full"
                        >
                            <Vue3Lottie
                                ref="lottieAnimationBell"
                                :animationData="lotties['bell']"
                                :height="40"
                                :width="40"
                                :loop="false"
                                :autoPlay="initialUserLikedType === 'bell'"
                                class="cursor-pointer"
                                @click="
                                    handleLottiePlay('bell');
                                    playLike('bellSimple');
                                    removeWindowVisibilityAfterLike($event);
                                "
                            />
                        </div>
                        <div
                            class="bg-[#212B3666] opacity-0 invisible group-hover:opacity-100 group-hover:visible left-1/2 -translate-x-1/2 backdrop-blur-xl absolute -top-[52px] transition-all py-1 px-1.5 text-xs text-white rounded-md whitespace-nowrap"
                        >
                            {{ $t("Ding-ding!") }}
                            <div
                                class="absolute -translate-x-1/2 -bottom-[6.7px] backdrop-blur-xl triangle left-1/2"
                            ></div>
                        </div>
                    </div>
                    <div class="relative group">
                        <div
                            class="flex relative items-center overflow-hidden group-hover:scale-[1.75] cursor-pointer origin-bottom transition-all justify-center w-8 h-8 p-0.5 border bg-[#FF9851] border-white rounded-full"
                        >
                            <Vue3Lottie
                                ref="lottieAnimationLike"
                                :animationData="lotties['like']"
                                :height="40"
                                :width="40"
                                :loop="false"
                                :autoPlay="initialUserLikedType === 'like'"
                                class="cursor-pointer"
                                @click="
                                    handleLottiePlay('like');
                                    removeWindowVisibilityAfterLike($event);
                                "
                            />
                        </div>
                        <div
                            class="bg-[#212B3666] opacity-0 invisible group-hover:opacity-100 group-hover:visible left-1/2 -translate-x-1/2 backdrop-blur-xl absolute -top-[52px] transition-all py-1 px-1.5 text-xs text-white rounded-md whitespace-nowrap"
                        >
                            {{ $t("Like!") }}
                            <div
                                class="absolute -translate-x-1/2 -bottom-[6.7px] backdrop-blur-xl triangle left-1/2"
                            ></div>
                        </div>
                    </div>
                    <div class="relative group">
                        <div
                            class="flex relative items-center group-hover:scale-[1.75] overflow-hidden cursor-pointer origin-bottom transition-all justify-center w-8 h-8 p-0.5 border bg-[#FF6161] border-white rounded-full"
                        >
                            <Vue3Lottie
                                ref="lottieAnimationLove"
                                :animationData="lotties['love']"
                                :height="40"
                                :width="40"
                                :loop="false"
                                :autoPlay="initialUserLikedType === 'love'"
                                class="rounded-full cursor-pointer"
                                @click="
                                    handleLottiePlay('love');
                                    removeWindowVisibilityAfterLike($event);
                                "
                            />
                        </div>
                        <div
                            class="bg-[#212B3666] opacity-0 invisible group-hover:opacity-100 group-hover:visible left-1/2 -translate-x-1/2 backdrop-blur-xl absolute -top-[52px] transition-all py-1 px-1.5 text-xs text-white rounded-md whitespace-nowrap"
                        >
                            {{ $t("Love!") }}
                            <div
                                class="absolute -translate-x-1/2 -bottom-[6.7px] backdrop-blur-xl triangle left-1/2"
                            ></div>
                        </div>
                    </div>
                    <div class="relative group">
                        <div
                            class="flex items-center group-hover:scale-[1.75] cursor-pointer overflow-hidden origin-bottom transition-all justify-center w-8 h-8 p-0.5 border bg-[#618E58] border-white rounded-full"
                        >
                            <Vue3Lottie
                                ref="lottieAnimationRocket"
                                :animationData="lotties['rocket']"
                                :height="40"
                                :width="40"
                                :loop="false"
                                :autoPlay="initialUserLikedType === 'rocket'"
                                class="rounded-full cursor-pointer"
                                @click="
                                    handleLottiePlay('rocket');
                                    removeWindowVisibilityAfterLike($event);
                                "
                            />
                        </div>
                        <div
                            class="bg-[#212B3666] opacity-0 invisible group-hover:opacity-100 group-hover:visible left-1/2 -translate-x-1/2 backdrop-blur-xl absolute -top-[52px] transition-all py-1 px-1.5 text-xs text-white rounded-md whitespace-nowrap"
                        >
                            {{ $t("Let's go!") }}
                            <div
                                class="absolute -translate-x-1/2 -bottom-[6.7px] backdrop-blur-xl triangle left-1/2"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.like-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

button {
    margin-right: 10px;
    padding: 5px 10px;
}

button.liked {
    color: red;
}

.liked-users .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 5px;
}
</style>
